import React from "react"
import Layout from "../components/layout";
import Sketch from "../components/sketch"
import sketch from "../scripts/not-a-wave"
import {graphql} from "gatsby";
import "../styles/font-style.css"
import SEO from "../components/seo";

export default function About({data}) {
  const post = data.markdownRemark
  return (
    <Layout>
      <SEO
        title={post.frontmatter.title}
        // description={post.frontmatter.description || post.excerpt || 'nothin’'}
        // image={post.frontmatter.image.childImageSharp.sizes.src}
        pathname={post.fields.slug}
        article
      />
      <div>
        <Sketch sketch={sketch}/>
        <div style={{
          display: `inline`,
          color: `white`,
          fontFamily: `Ubuntu`,
          textShadow: `none`,
          textDecoration: `none`,
        }} dangerouslySetInnerHTML={{__html: post.html}}/>
        {/*<a style={{*/}
        {/*  textShadow: `none`,*/}
        {/*  backgroundImage: `none`,*/}
        {/*  display: `inline`,*/}
        {/*  color: `#ffb3e7`,*/}
        {/*  fontFamily: `Ubuntu`*/}
        {/*}} href="https://twitter.com/kreide_io">Write me on Twitter</a>*/}
        {/*<a style={{*/}
        {/*  textShadow: `none`,*/}
        {/*  backgroundImage: `none`,*/}
        {/*  display: `inline`,*/}
        {/*  color: `#ffb3e7`,*/}
        {/*  fontFamily: `Ubuntu`*/}
        {/*}} href="https://twitter.com/kreide_io">Write me on Twitter</a>*/}
      </div>
    </Layout>
  )
}

export const query = graphql`
    query PostQuery($slug: String!) {
        markdownRemark(fields: { slug: { eq: $slug } }) {
            html
            frontmatter {
                title
            }
            fields {
                slug
            }
        }
    }
`