/* eslint-disable no-multi-assign */
/* eslint-disable no-param-reassign */

// We'll ride the spiral to the end and may just go where no one's been
// Spiral out, keep going ...

import img from "./bahn4.gif"

export default p => {
  p.state = []
  p.dispatch = () => {
  }
  let y = 1;
  let trainImg;
  let kosmoImg;
  let highImg;
  let potatoImg;
  let c = 0;
  let g = 1;
  let sigma = p.windowHeight / 6;
  let x = 1;
  let w = 1;
  let h = 1;

  p.windowResized = () => {
    p.resizeCanvas(p.windowWidth - 15, p.windowHeight)
  }

  p.preload = () => {
    trainImg = p.loadImage(img);
    // kosmoImg = p.loadImage(kosmo);
    // highImg = p.loadImage(high);
    // potatoImg = p.loadImage(potato);
  }

  p.setup = () => {
    p.createCanvas(p.windowWidth - 15, p.windowHeight)
    p.smooth()
    p.stroke(255); // Set line drawing color to white
    p.frameRate(20);
  }

  p.draw = () => {
    // w = trainImg.weight;
    // h = trainImg.height;
    y = y + 1;
    if (0 === y % 3) {
      g = 1 / (sigma * p.sqrt(2 * p.PI))
      x = (y - p.windowHeight / 2) / sigma
      c = p.min(80000 * g * p.exp(-0.5 * x * x), 255);
      p.background(0)
      p.tint(255, c)
      if (p.windowHeight > p.windowWidth){
        w = 2.5;
      } else {
        w = 4;
      }
      p.image(trainImg, p.windowWidth / 2 - w * trainImg.width / 2 , 0, trainImg.width * w, trainImg.height * w)
    //   p.image(
    //     kosmoImg,
    //     p.windowWidth / 2 - p.windowWidth / 4,
    //     p.windowHeight / 2 - p.windowHeight / 4,
    //     kosmoImg.width / 2,
    //     kosmoImg.height / 2
    //   )
    //
    //   p.image(
    //     highImg,
    //     p.windowWidth / 2,
    //     p.windowHeight / 2 + p.windowHeight / 4,
    //     highImg.width / 10,
    //     highImg.height / 10
    //   )
    }
    if (y > p.windowHeight) {
      y = 0;
    }
  }
}
